<template>
    <v-app-bar color="white" white fixed hide-on-scroll app>
        <v-btn icon @click="backPage">
            <v-icon>chevron_left</v-icon>
        </v-btn>

        <v-toolbar-title class="pl-0">{{ toolbar_title }}</v-toolbar-title>
    </v-app-bar>
</template>

<script>
export default {
    name: "AppBar",
    data() {
        return {
            // toolbar_title: null,
        };
    },

    computed : {
        toolbar_title() {
            console.log('Computed........................................')
            let title = this.$store.getters.getSubPage;
            console.log('Sub title name : ', title )
            return title
            // return null
        }
    },

    created() {
        this.setTitle();
    },

    methods: {
        backPage() {
            // this.$router.push('/myinfo')
            let checkSubPage = this.$store.getters.getSubPage 
            console.log('checkSubPage : ', checkSubPage)
            if(checkSubPage == '충전금액') {
                this.$store.commit({ type: "setSubPage", sub_page: "통장" });
                // this.$router.push({path: '/detail/ledger'});
                this.$router.go(-1);
            } else if(checkSubPage == '통장') {
                this.$store.commit({ type: "setSubPage", sub_page: "main" });
                this.$router.go(-1);
                // this.$router.push("/detail/ledger");
            } else if(checkSubPage == '출금') {
                console.log('출금 .................................... : ', checkSubPage)
                this.$store.commit({ type: "setSubPage", sub_page: "통장" });
                // this.$router.go(-1);
                this.$router.push("/detail/ledger");
            } else {
                this.$router.go(-1);
            }
            
        },
        setTitle() {
            // this.toolbar_title = this.$store.getters.getSubPage;
            // console.log('Sub title name : ', this.toolbar_title )
        },
    },
};
</script>

<style lang="scss" scoped>
.v-toolbar__title {
    font-size: 1rem !important;
    color: #969696;
}
.v-app-bar {
  position: relative;
  width: 100%;
  height: 60px;
  margin: 0 auto;
  padding: 0 10px;
  background: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05) !important;
}
</style>